import React from 'react';
import { withSvgIcon } from './SvgIcon';

export const MailBoxIcon = withSvgIcon((props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22.172" height="22.172" viewBox="0 0 22.172 22.172"
    {...props}
  >
  <defs>
    <clipPath id="clip-path">
      <rect width="22.172" height="22.172" fill="none"/>
    </clipPath>
  </defs>
  <g id="Messages" clip-path="url(#clip-path)">
    <rect id="Rectangle_117" data-name="Rectangle 117" width="22.172" height="22.172" fill="none"/>
    <path id="Path_50" data-name="Path 50" d="M19.4,3.029H2.772L11.086,10.6ZM0,3.029A2.917,2.917,0,0,1,2.772,0H19.4a2.917,2.917,0,0,1,2.772,3.029V15.144A2.917,2.917,0,0,1,19.4,18.172H2.772A2.917,2.917,0,0,1,0,15.144Z" transform="translate(0 2)" fill="#fff" fill-rule="evenodd"/>
  </g>
</svg>
))