import { useScrollContext } from "@app/context/ScrollContext"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useScrollSection } from "react-scroll-section"
import { useWindowScrollPercentage } from "./ScrollVisual/useWindowScrollPercentage"

const defaultNavItems = [
  {
    id: 'crystal',
    name: 'crystal',
    path: '#crystal',
    className: 'crystal',
    releated: ['introduction']
  },
  {
    id: 'projects',
    name: 'projects',
    path: '#projects'
  },
  {
    id: 'contact',
    name: 'contact',
    path: '#contact',
    className: 'contact'
  }
]

const NavItem = ({path, name, id, active, onClick, className, navActiveElementIds}) => {
  const ref = useRef(null);
  const [isActive, setActive] = useState(active);
  // const section = useScrollSection(id);
  // const handleClick = (e, s) =>{
  //   e.preventDefault();
  //   document && document.getElementById(id).scrollIntoView({
  //     behavior: 'smooth'
  //   });
  // }
  useEffect(() => {
    const activeElements = navActiveElementIds.map(
      navActiveElementId => document.getElementById(navActiveElementId)
    ).filter(x => x);

    const contactElement = document.getElementById('contact');
    const handler = (el) => {
      activeElements.forEach((elm) => {
        const rect = ref.current.getBoundingClientRect(); // top = rect.top, height = rect.height;
        // if (elm.id === 'contact') {
        //   if (elm.getBoundingClientRect().top < rect.top) {
        //     setActive(true);
        //   } else {
        //     setActive(false);
        //   }
        // } else
        if (elm.getBoundingClientRect().top > rect.top || (contactElement && contactElement.getBoundingClientRect().top < rect.top)) {
          setActive(true);
        } else {
          setActive(false);
        }
      })
    }
    if (activeElements && activeElements.length)
      window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler);
    }
  }, [])
  
  return (
    <li
      ref={ref}
      className={`nav-item${className ? ' ' + className : ''}`}
    >
      {id ?
        <a href={path} className={`${active ? ' active' : ''}${isActive ? ' in-header' : ''}`} onClick={onClick}>{name}</a>
        : <Link to={path} activeClassName="active" className={`${active ? ' active' : ''}${isActive ? ' in-header' : ''}`}>
          {name}
        </Link>}
      
    </li>
  )
}

const Header = ({ siteTitle, navItems = defaultNavItems, navActiveElementIds = ['crystal'] }) => {
  const [offset, percent] = useWindowScrollPercentage();
  const [isSticky, setSticky] = useState(false);
  const sectionCtx = useScrollContext();

  const handleClick = (e, id) =>{
    e.preventDefault();
    sectionCtx.setActive()
    document && document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    const el = document && document.getElementById('introduction');
    if (el && offset > (el.clientHeight - 45)) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [offset])
  return (
    <header>
      <nav className={`${isSticky ? ' sticky':''}`}>
        <ul className="nav-menu container-sm mb-0">
          {navItems.map((item) => {
            return (
              <NavItem
                id={item.id}
                path={item.path}
                name={item.name}
                navActiveElementIds={navActiveElementIds}
                className={item.className}
                active={sectionCtx.selected === item.id || item.releated?.find(x => sectionCtx.selected === x) || item.active}
                onClick={(e) => handleClick(e, item.id)}
              />
            )
          })}
        </ul>
      </nav>
    </header>
  )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
