import React from 'react';
import { withSvgIcon } from './SvgIcon';

export const MarkerIcon = withSvgIcon(({color, ...props}: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="29.586" height="29.586"
    viewBox="0 0 29.586 29.586"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect width="29.586" height="29.586" fill="none"/>
      </clipPath>
    </defs>
    <g id="Places" clip-path="url(#clip-path)">
      <rect id="Rectangle_305" data-name="Rectangle 305" width="29.586" height="29.586" transform="translate(0 0)" fill="none"/>
      <path 
        id="Path_114"
        style={{fill: color}}
        data-name="Path 114" d="M10.884,15.722A4.616,4.616,0,0,0,15.508,11.1a4.758,4.758,0,0,0-4.624-4.789A4.616,4.616,0,0,0,6.26,10.933,4.874,4.874,0,0,0,10.884,15.722ZM3.122,3.171A10.977,10.977,0,0,1,18.646,18.695l-7.762,7.762L3.122,18.695A11.277,11.277,0,0,1,3.122,3.171Z" transform="translate(0.975 0.05)" fill="#fff" fill-rule="evenodd"/>
    </g>
  </svg>
))