import React from 'react';

export type SvgIconProps = {
  size?: number;
}

export const withSvgIcon = (Icon: any) => {
  return ({size = 3.9, ...props}: SvgIconProps) => {
    return <>
      <Icon
        style={{
          width: `${size}rem`,
          height: `${size}rem`,
        }}
        {...props}
      />
    </>
  }
}