import { debounce } from '@app/utils/debounce';
import React, { createRef, useContext } from 'react';

export type ScrollContextState = {
  selected?: string;
  setActive: (id: string) => void,
  registerRef: (id: string) => any,
}

const initialState: ScrollContextState = {
  setActive: () => {},
  registerRef: () => {}
}

const Context = React.createContext<ScrollContextState>(initialState);
Context.displayName = 'ScrollContext';

export const useScrollContext = () => useContext(Context);
export const ScrollContextProvider = Context.Provider;
export const ScrollContextConsumer = Context.Consumer;

export class ScrollContextWrapper extends React.Component<any, ScrollContextState> {
  state = initialState;
  REFS: any = {};

  setActive = (id: string) => {
    this.setState({
      selected: id
    });
  }

  componentDidMount() {
    // document.addEventListener('scroll', this.debounceScroll, true);
    // this.handleScroll();
  }

  componentWillUnmount() {
    // document.removeEventListener('scroll', this.debounceScroll, true);

  }
  handleScroll = () => {
    var selectedSection = Object.keys(this.REFS).reduce((acc, id) => {
      var sectionRef = this.REFS[id] && this.REFS[id].current;
      if (!sectionRef) {
          return {
              id: id,
              differenceFromTop: 0,
          };
      }
      var top = sectionRef.getBoundingClientRect().top;
      var differenceFromTop = Math.abs(top);
      if (differenceFromTop >= acc.differenceFromTop)
          return acc;
      return {
          differenceFromTop: differenceFromTop,
          id: id,
      };
    }, {
      differenceFromTop: 9999,
      id: '',
    });
    console.log(this.state, selectedSection)
    if (this.state.selected !== selectedSection.id)
      this.setActive(selectedSection.id);
  }
  debounceScroll = debounce(this.handleScroll, 50);

  registerRef = (id: string) => {
    const ref = createRef();
    this.REFS[id] = ref;
    return ref;
  }

  render() {
    return (
      <ScrollContextProvider value={{
        ...this.state,
        setActive: this.setActive,
        registerRef: this.registerRef
      }}>
        {this.props.children}
      </ScrollContextProvider>
    )
  }
}