import React from 'react';
import { withSvgIcon } from './SvgIcon';

export const FacebookIcon = withSvgIcon((props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.345" height="26.62" viewBox="0 0 13.345 26.62"
    {...props}
    style={{
      width: '0.8rem',
      height: '2.6rem'
    }}
  >
  <path id="facebook" d="M384.9,5526.064h3.432V5521H384.3v.018c-4.885.172-5.888,2.919-5.976,5.8h-.01v2.531H374.99v4.963h3.326v13.3h5.016v-13.3h4.108l.791-4.963h-4.9v-1.529A1.634,1.634,0,0,1,384.9,5526.064Z" transform="translate(-374.99 -5520.997)"/>
</svg>
))