/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import Header from "./header"
import { Section } from "./Section";
import { MarkerIcon } from "./icons/Marker";
import { PhoneIcon } from "./icons/Phone";
import { MailBoxIcon } from "./icons/MailBox";
import { LinkedInIcon } from "./icons/LinkedIn";
import { FacebookIcon } from "./icons/Facebook";
import { MediumIcon } from "./icons/Medium";
// import { PaperPlaneIcon } from "./icons/PaperPlane";
// import { ScrollVisual } from './ScrollVisual';
import Fade from 'react-reveal/Fade';
import { PostImage } from "./PostImage";
// import {
//   ScrollingProvider,
//   // useScrollSection,
// } from 'react-scroll-section';
import { ScrollContextWrapper } from "@app/context/ScrollContext"
import logo from '@app/assets/images/logo.png';
import Helmet from 'react-helmet';

const shortcodes = {
  Fade, 
  'post-image': PostImage
}

const Layout = ({ children, className, navItems, title, description, keywords, navActiveElementIds }: any) => {
  const data = useStaticQuery(graphql`
    query SiteMetaDataQuery {
      site {
        siteMetadata {
          title
          description
          keywords
          author
        }
      }
    }
  `)

  return (
    <ScrollContextWrapper
      offset={200}
      scrollBehavior="smooth"
      debounceDelay={0}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? title + ' - ' : ''}{data.site.siteMetadata.title}</title>
        <meta name="description" content={`${description ? description +', ': ''}` + (data.site.siteMetadata.description || '')}/>
        <meta name="keywords" content={(keywords ? keywords +', ' : '') + (data.site.siteMetadata.keywords || '')}/>
        <meta name="author" content={data.site.siteMetadata.author}/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      </Helmet>
      <div className="logo-container hide-sm hide-md">
        <div className="container logo-img">
          <Link to="/" className="logo-text" style={{ color: 'white', fontWeight: 'bold', fontSize: '2rem' }}>
            {/* <img src={logo} /> */}
            crystal.
          </Link>
        </div>
      </div>
      <Header
        siteTitle={data.site.siteMetadata.title}
        navItems={navItems}
        navActiveElementIds={navActiveElementIds}
      />
      <main className={className}>
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
      </main>
      <footer>
        <Section
          id="contact"
          index="3"
          heading="contact"
          disableAnimate
        >
          <div className="contact">
            <a
              href="mailto:info@thecrystal.tech"
              className="btn btn-secondary"
            >
              get in touch
            </a>
            {/* <a href="tel:+84812346111" className="btn btn-secondary ml-5">
              <PhoneIcon
                size={1.9}
              />
            </a>
            <a
              href="https://crystal-techs.com/#contact-view"
              className="btn btn-secondary ml-5"
              target={'_blank'}
            >
              <MarkerIcon
                color="black"
                size={1.9}
              />
            </a> */}
          </div>
          <p
            className="address text-white"
          >
            <span className="address-icon">
              <MarkerIcon
                size={2}
              />
            </span>
            68 Circular Road, #02-01, 049422, Singapore
            <br/>
            Level 6, Zen Tower, 12 Khuat Duy Tien Str., Thanh Xuan Trung Ward, Thanh Xuan Dist., Hanoi, Vietnam
          </p>
          <div className="mailbox">
            <a href="mailto:info@thecrystal.tech" className="text-white">
              <MailBoxIcon
                size={1.6}
              /> <span className="ml-4">info@thecrystal.tech</span>
            </a>
          </div>
          <div className="crystal">
            <h4 className="text">crystal.</h4>
            <p className="text-sm">digital consulting studio</p>
          </div>
          <div className="socials">
            <a
              href="https://www.facebook.com/crystaltechpage"
              target={'_blank'}
              className="btn btn-secondary btn-icon"
            >
              <FacebookIcon
                
              />
            </a>
            <a
              href="https://www.linkedin.com/company/crystaltechnology"
              target="_blank"
              className="btn btn-secondary btn-icon ml-4"
            >
              <LinkedInIcon
                size={1.6}
              />
            </a>
            <a
              href="https://medium.com/@crystaltech"
              target={'_blank'}
              className="btn btn-secondary btn-icon ml-4"
            >
              <MediumIcon
                size={2}
              />
            </a>
          </div>
          {/* <form className="newsletter-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="sign up to our newsletter"
              />
              <span className="right-icon">
                <PaperPlaneIcon
                  size={2.7}
                />
              </span>
            </div>
          </form> */}
          <p className="copyright">Copyright © {(new Date()).getUTCFullYear()} Crystal Technologies JSC. All right reserved.</p>
        </Section>
      </footer>
    </ScrollContextWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
