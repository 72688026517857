export const debounce = function (func: Function, waitFor?: number) {
  let timeout: any = null;
  const debounced = function () {
    let args: any = [];
    for (let _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
    }
    timeout = setTimeout(function () { return func.apply(void 0, args); }, waitFor);
  };
  return debounced;
};