import React, { useEffect, useState } from 'react';

export function useWindowScrollPercentage () {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return [0,0];
    } 
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return [offset, (offset / window.document.body.clientHeight) * 100]
};