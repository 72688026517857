import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Fade } from './animations/Fade';
// import Fade from 'react-reveal/Fade';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useState } from 'react';
import useIntersection from '@app/hooks/useIntersection';
import { ScrollVisual } from './ScrollVisual';
import { useScrollContext } from '@app/context/ScrollContext';

export type SectionProps = React.HTMLAttributes<HTMLDivElement> & {
  id: string,
  index?: string,
  heading?: any,
  disableAnimate?: boolean,
  backgroundImage?: any
  fixed?: boolean,
  vCentered?: boolean,
  meta?: any,
  visualScroll?: boolean,
  scrollTo?: string,
  contentClassName?: string
}

export const Section = ({
  fixed, backgroundImage,
  vCentered, id, meta, className,
  visualScroll, heading, index, scrollTo,
  contentClassName,
  ...props
}: SectionProps) => {
  const bgref = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number>(0);
  const sectionCtx = useScrollContext();
  const ref = useMemo(function() { return sectionCtx.registerRef(id) }, [id]);
  const [visible] = useIntersection(ref, '0px', 0.5)
  const classes = `section${className ? ' ' + className : ''}${sectionCtx.selected === id ? ' section--active' : ''}`;

  useEffect(() => {
    if (visible) {
      sectionCtx.setActive(id);
    }
  }, [visible])

  useEffect(() => {
    if (bgref.current) {
      setTimeout(() => {
        setTop(Number(bgref?.current?.clientHeight));
      }, 100)
    }
  }, [bgref.current?.clientHeight])

  const handleScrollTo = (e: any) => {
    e.preventDefault();
    if (!scrollTo) return;
    const el = document && document.getElementById(scrollTo)
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <>
    <section
      id={id}
      ref={ref}
      {...props}
      className={classes}
      style={fixed ? { position: 'fixed' } : {}}
    >
      {/* <div
        {...props}
        id={`${id}--content`}
        className={`section${props.className ? ' ' + props.className : ''}`}
        style={fixed ? { position: 'fixed' } : {}}
      > */}
        {backgroundImage && <Fade
          ref={bgref}
          as='div'
          className='section-bg-image'
          direction=''
        >
          <GatsbyImage image={backgroundImage} />
        </Fade>}
        <div className={`wrapper-content container${vCentered ? ' v-centered' : ''}${contentClassName ? ' ' + contentClassName :''}`}>
          <div className="row">
            <div className="col-md-12">
              {heading &&
                <Fade as="h2" className="section-heading">
                  {index && <div className="section-number">
                    {index}
                  </div>}
                  <span className='section-heading--text'>{heading}</span>
                </Fade>}
              <div className="section-content">
                {props.children}
              </div>
            </div>
          </div>
        </div>
        { visualScroll && 
          <div className='wrapper wrapper--scroll-down'>
            <div className='wrapper wrapper--container container'>
              <div className='row'>
                <div className='col-12'>
                  <a href={`#${scrollTo}`} onClick={handleScrollTo} className="scroll-down link link--js d-flex align-items-center">
                    <div className="wrapper wrapper--icon icon icon--down">
                      <svg width="9" height="11" viewBox="0 0 9 11" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.778 10.938V.5M1.054 6.933l3.724 4.005L8.5 6.933" stroke="#FFF" fill="none"></path>
                      </svg>
                    </div>
                    <div className="wrapper wrapper--label">Scroll down</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        }
      {/* </div> */}
    </section>
    {fixed && <div style={{marginTop: top}} />}
    </>
  )
}